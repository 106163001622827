import { useWhoAmI } from '@app/api/auth.api';
import { useResponsive } from '@app/hooks/useResponsive';
import { getEnumValue } from '@app/services/enum.service';
import { RootState } from '@app/store/store';
import { LiveChatWidget } from '@livechat/widget-react';
import config from 'config.dev.json';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../../header/Header';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import MainSider from '../sider/MainSider/MainSider';
import * as S from './MainLayout.styles';
import AlertBanner from '@app/components/common/AlertBanner/AlertBanner';
import AccountStatusModals from '@app/components/common/AccountStatusModals';
import TermsAndConditionsModal from '@app/components/auth/TermsAndConditions/TermsAndConditionsModal';
import { useTermsAndConditionsModal } from '@app/components/auth/TermsAndConditions/useTermsAndConditionsModal';
import { getImpersonationToken } from '@app/services/localStorage.service';

const enabledStatusFilter = [
  {
    column: 'status',
    enumKey: 'AccountStatus',
    value: JSON.stringify([1]),
    type: 'enum',
    title: 'Status',
  },
];

const MainLayout: React.FC = () => {
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector<RootState>((state) => state.user.isAuthenticated);
  const userRole: number = useSelector<RootState>((state) => state.user.user?.role) as number;
  const userEmail: string = useSelector<RootState>((state) => state.user.user?.email) as string;
  const { isFetching, data: userData } = useWhoAmI();

  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const toggleSider = () => setSiderCollapsed(!siderCollapsed);
  const impersonationToken = getImpersonationToken();
  const { terms, openTermsModal, setOpenTermsModal } = useTermsAndConditionsModal(
    userRole,
    userData?.termsApproved,
    impersonationToken,
  );

  useEffect(() => {
    if (isFetching) return;

    if (isAuthenticated === false) {
      return navigate('/auth/login', { replace: true });
    }

    if (location.pathname === '/') {
      if (
        userRole != null &&
        [getEnumValue('UserRole', 'SuperAdmin'), getEnumValue('UserRole', 'Vendor')].includes(userRole)
      ) {
        if (!location.pathname.includes('manage-accounts')) {
          navigate(`/manage-accounts?$filter=status eq 1`);
        }
        return;
      }
      navigate('/dashboard');
    }
  }, [userRole, isFetching, location, isAuthenticated]);
  // useEffect(() => {
  //   const isHomePage = location.pathname === '/';
  //   const statesPopulated = appState.appValues != null && appState.rolesAndScreens != null && userState.user != null;

  //   if (isAuthenticated && !isHomePage && statesPopulated) {
  //     const dashboardScreens = (appState.appValues as any)['DashboardScreens'];
  //     const rolesAndScreens = appState.rolesAndScreens || [];
  //     const userRole = userState.user?.role || 0;
  //     if (!isScreenAllowed(location.pathname, dashboardScreens, rolesAndScreens, userRole)) {
  //       navigate('/');
  //     }
  //   }
  // }, [location.pathname, appState, userState]);

  useEffect(() => {
    setIsTwoColumnsLayout(['/'].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      {isAuthenticated && userRole !== getEnumValue('UserRole', 'SuperAdmin') ? (
        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
          <LiveChatWidget license={config.liveChatLicense} customerEmail={userEmail} customerName={userEmail} />
        </div>
      ) : null}

      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        {userRole !== getEnumValue('UserRole', 'SuperAdmin') && (
          <>
            <AccountStatusModals />
            <AlertBanner />
          </>
        )}
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
      {terms && (
        <TermsAndConditionsModal
          open={openTermsModal}
          terms={terms}
          newVersion
          onCancel={() => setOpenTermsModal(false)}
        />
      )}
    </S.LayoutMaster>
  );
};

export default MainLayout;
