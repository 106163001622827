import { BaseErrorMessage, HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import Spin from 'antd/lib/spin';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Select } from 'antd';
import { TAccountNotifications } from '@app/types/accountNotifications';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { TTermsAndConditions } from '@app/types/termsAndConditions';
import { getEnumStatusOptions } from '@app/utils/utils';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { TOption } from '../manage-recommendations/RecommendationForm';
import { getEnumValue } from '@app/services/enum.service';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const SaveAndPublishButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 12px 28px;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TTermsAndConditionsForm = {
  type: number;
  content: string;
};

interface ITermsAndConditionsFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isCreating?: boolean;
  terms?: TTermsAndConditions;
  isPublishing?: boolean;
  isUpdating?: boolean;
}

export default function TermsAndConditionsForm({
  isEdit,
  isCreating,
  isPublishing,
  isUpdating,
  terms,
  ...modalProps
}: ITermsAndConditionsFormProps) {
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<Partial<TTermsAndConditions>>({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (modalProps.open && !!terms) {
      reset({
        type: getEnumValue('TermsAndConditionsTypes', terms.type.toString().replaceAll(' ', '')),
        content: terms.content,
      });
    } else if (modalProps.open && !terms) {
      reset({
        type: undefined,
        content: '',
      });
    }
  }, [modalProps.open, terms]);

  const canPublish = (terms?.status as any) == 'Draft';
  const typeOptions = useAppSelector((state) => (state.app.appValues as any)?.TermsAndConditionsTypes) as TOption[];
  const onSubmit: SubmitHandler<Partial<TTermsAndConditions>> = (data) => {
    modalProps.onOk?.(data, false);
  };

  const handleSaveAndPublish: SubmitHandler<Partial<TTermsAndConditions>> = (data) => {
    modalProps.onOk?.(data, true);
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  const isLoading = isCreating || isPublishing || isUpdating ? true : false;

  return (
    <Modal
      style={{
        minWidth: '1000px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{`${isEdit ? 'Edit' : 'Create'} Terms And Conditions`}</FormTitle>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Type:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="type"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={field.value}
                    showSearch
                    style={{ flexBasis: '75%' }}
                    options={typeOptions}
                    optionFilterProp="children"
                    status={errors.type ? 'error' : ''}
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  >
                    {typeOptions.map((opt) => (
                      <Select.Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
              {!!errors.type && <BaseErrorMessage>This field is required</BaseErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Content</FieldLabel>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <InputContainer>
              <Controller
                control={control}
                name="content"
                rules={{ required: true }}
                render={({ field }) => (
                  <HtmlEditor
                    height={300}
                    initialValue={field.value || ''}
                    onChange={(content) => setValue('content', content)}
                  />
                )}
              />
              {!!errors.content && <BaseErrorMessage>This field is required</BaseErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
        </FormContainer>
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton>{isEdit ? 'Update' : 'Save'}</CreateButton>
              {(!isEdit || canPublish) && (
                <SaveAndPublishButton type="button" onClick={handleSubmit(handleSaveAndPublish)}>
                  {isEdit ? 'Update and Publish' : 'Save and Publish'}
                </SaveAndPublishButton>
              )}
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}
