/* eslint-disable no-var */
import { useTranslation } from 'react-i18next';

import RegulationsForm from '@app/components/apps/manage-regulations/RegulationsForm';
import useRegulationsForm from '@app/components/apps/manage-regulations/useRegulationsForm';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import RegulationsModal from '@app/pages/RegulationsControlsModal';
import { useState } from 'react';

import { useCreateRegulation, useUpdateRegulation } from '@app/api/regulation.api';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import ExportExcelButton from '@app/components/tables/data-table/components/export-excel/ExportExcelButton';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { getEnumValue } from '@app/services/enum.service';
import { TRegulations } from '@app/types/regulations';

const regulationsComplianceCols: TDataColumns<TRegulations> = [
  {
    title: 'Name',
    dataIndex: 'name',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '10vw',
  },
  {
    title: 'Summary',
    dataIndex: 'summary',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '15vw',
    hideColumn: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: '15vw',
    hideColumn: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'RegulationStatus',
    showSortDirections: true,
    width: 200,
    hideColumn: false,
  },
];

export default function ManageRegulationsPage() {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRegulation, setSelectedRegulation] = useState<TRegulations | undefined>(undefined);
  const [IsControlsModalOpen, setIsControlsModalOpen] = useState(false);

  const { t } = useTranslation();
  const { mutateAsync: createRegulation, isLoading: isCreating } = useCreateRegulation();
  const { mutateAsync: updateRegulation, isLoading: isUpdating } = useUpdateRegulation();

  const { toggle, ...regulationFormProps } = useRegulationsForm({
    onOk: async (data) => {
      if (isEdit && !!selectedRegulation) {
        await updateRegulation({
          id: selectedRegulation.id,
          data: data,
        });
      } else {
        await createRegulation(data);
      }

      setIsEdit(false);
      setSelectedRegulation(undefined);
      toggle();
      tableFilterProps.refetch();
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedRegulation(undefined);
    },
    regulation: selectedRegulation,
  });

  const actionItems: TActionItems<TRegulations>[] = [
    {
      label: 'Update Regulation',
      key: 'update-regulation',
      multiSelect: false,
      onClick: (item) => {
        setIsEdit(true);
        setSelectedRegulation(item);
        toggle();
      },
    },
    {
      label: 'Manage Controls',
      key: 'manage-controls',
      multiSelect: false,
      onClick: (item) => {
        setSelectedRegulation(item);
        setIsControlsModalOpen(true);
      },
    },
  ];

  const tableFilterProps = useTableFiltersV2<TRegulations>({
    columns: regulationsComplianceCols,
    model: 'Regulation',
    searchableColumns: ['name', 'description'],
    constantFilter: {
      or: [
        { status: getEnumValue('RegulationStatus', 'Active') },
        { status: getEnumValue('RegulationStatus', 'Hidden') },
      ],
    },
    orderBy: [
      {
        column: 'status',
        order: 'asc',
      },
      {
        column: 'name',
        order: 'asc',
      },
    ],
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  var dataTableProps = useDataTable<TRegulations>({
    data: tableFilterProps.data?.items || [],
    columns: regulationsComplianceCols,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: (data) => {
      setIsEdit(true);
      setSelectedRegulation(data);
      toggle();
    },
  });

  // const dataTableProps = useDataTable<TRegulations>({
  //   model: 'Regulation',
  //   columns: regulationsComplianceCols,
  //   stickyHeader: true,
  //   searchableColumns: ['name', 'description'],
  //   constantFilter: '(status eq 1)',
  //   exportExcel: { type: 'All', model: 'Regulation' },
  //   // importExcel: { model: 'Regulation' },
  //   defaultOrder: {
  //     column: 'name',
  //     order: 'asc',
  //   },
  //   actionItems,
  //   onRowClick: (data, index) => {
  //     setIsEdit(true);
  //     setSelectedRegulation(data);
  //     toggle();
  //   },
  // });

  return (
    <>
      <PageTitle>{t('common.manageRegulations')}</PageTitle>

      <RegulationsModal
        open={IsControlsModalOpen}
        regulationData={{
          RegulationId: selectedRegulation ? selectedRegulation.id : 0,
          RegulationName: selectedRegulation?.name || '',
        }}
        onCancel={() => setIsControlsModalOpen(false)}
      />
      <ComponentHeader title={t('common.manageRegulations')} />
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <BaseCTAButton onClick={toggle}>Create Regulation</BaseCTAButton>
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
          <ExportExcelButton exportType={{ type: 'All', model: 'Regulation' }} columns={regulationsComplianceCols} />
        </DataTableV2.Actions>
      </DataTableV2>
      <RegulationsForm {...regulationFormProps} isEdit={isEdit} isLoading={isCreating || isUpdating} />
    </>
  );
}
