import { Popover } from 'antd';

import { parseDbEnumArray } from '@app/components/common/common';
import CloudSyncIcon from '@app/components/icons/cloud-sync/CloudSync';
import SeverityBadge from '@app/components/tables/AntdTableWrapper/components/SeverityBadge';
import {
  ComplianceStatusBadge,
  ExemptionStatusBadge,
} from '@app/components/tables/AntdTableWrapper/components/StatusBadge';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useHasActionPermission } from '@app/hooks/useHasActionPermission';
import { useResponsive } from '@app/hooks/useResponsive';
import { getEnumLabel } from '@app/services/enum.service';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { useCallback, useState } from 'react';
import { useSetExemptRecommendation } from '@app/api/recommendation.api';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { getOneLineStyle, stripHtml } from '@app/utils/utils';

interface IUseRecommendationTableColumnsAndActions {
  onAdditionalDetailsModalOpen?: (item: TAccountRecommendationExtended) => void;
  onExempt?: (items: TAccountRecommendationExtended[]) => void;
  onUnExempt?: (items: TAccountRecommendationExtended[]) => void;
  onMarkCompliant?: (items: TAccountRecommendationExtended[]) => void;
  onMarkNonCompliant?: (items: TAccountRecommendationExtended[]) => void;
  onAssign?: (items: TAccountRecommendationExtended[]) => void;
}

export const useRecommendationTableColumnsAndActions = ({
  onAdditionalDetailsModalOpen,
  onExempt,
  onUnExempt,
  onMarkCompliant,
  onMarkNonCompliant,
  onAssign,
}: IUseRecommendationTableColumnsAndActions) => {
  const { isBigScreen } = useResponsive();

  const productOptions =
    useAppSelector((state) => state.app.products)?.map((opt) => ({
      key: opt.displayName,
      value: opt.displayName,
    })) || [];
  const licenseOptions =
    useAppSelector((state) => state.app.licenses)?.map((opt) => ({
      key: opt.name,
      value: opt.displayName,
    })) || [];

  const hasAssignPermission = useHasActionPermission('Assign Recommendation');
  const hasExemptPermission = useHasActionPermission('Exempt Recommendation');
  const hasUpdateCompliancePermission = useHasActionPermission('Update Recommendation Compliance');

  const { mutateAsync: setExempt } = useSetExemptRecommendation();

  // selected rows state
  const [selectedRows, setSelectedRows] = useState<TAccountRecommendationExtended[]>([]);
  const [selectedRecommendation, setSelectedRecommendation] = useState<TAccountRecommendationExtended | undefined>();

  // modals state
  const [isAdditionalDetailsModalOpen, setIsAdditionalDetailsModalOpen] = useState(false);
  const [isExemptModalOpen, setIsExemptModalOpen] = useState(false);
  const [isAssignRecommendationModalOpen, setIsAssignRecommendationModalOpen] = useState(false);
  const [isMarkComplianceModalOpen, setIsMarkComplianceModalOpen] = useState(false);

  // compliance action state
  const [complianceAction, setComplianceAction] = useState<'mark-compliant' | 'mark-non-compliant'>('mark-compliant');

  const handleOpenAdditionalDetailsModal = (item: TAccountRecommendationExtended) => {
    setSelectedRecommendation(item);
    setIsAdditionalDetailsModalOpen(true);
    onAdditionalDetailsModalOpen?.(item);
  };

  const handleSetExemptRecommendation = (items: TAccountRecommendationExtended[]) => {
    // add current recommendation to selected rows if not present already
    setSelectedRows(items);
    setIsExemptModalOpen(true);
    onExempt?.(items);
  };

  const handleSetUnexemptRecommendation = async (items: TAccountRecommendationExtended[]) => {
    // add current recommendation to selected rows if not present already
    setSelectedRows(items);
    const response = await setExempt({
      accountRecommendationIds: selectedRows.map((row) => row.id),
      exempt: false,
    });
    onUnExempt?.(items);
  };

  const handleAssignRecommendation = (items: TAccountRecommendationExtended[]) => {
    setSelectedRows(items);
    setIsAssignRecommendationModalOpen(true);
    onAssign?.(items);
  };

  const handleMarkCompliance = (items: TAccountRecommendationExtended[]) => {
    setSelectedRows(items);
    setIsMarkComplianceModalOpen(true);
  };

  const toggleAdditionalDetailsModal = useCallback(
    (open?: boolean) => {
      const isOpen = open || !isAdditionalDetailsModalOpen;

      setIsAdditionalDetailsModalOpen(isOpen);
    },
    [isAdditionalDetailsModalOpen],
  );

  const toggleExemptModal = useCallback(
    (open?: boolean) => {
      const isOpen = open || !isExemptModalOpen;

      setIsExemptModalOpen(isOpen);
    },
    [isExemptModalOpen],
  );

  const toggleComplianceModal = useCallback(
    (open?: boolean) => {
      const isOpen = open || !isMarkComplianceModalOpen;

      setIsMarkComplianceModalOpen(isOpen);
    },
    [isMarkComplianceModalOpen],
  );

  const toggleAssignModalOpen = useCallback(
    (open?: boolean) => {
      const isOpen = open || !isAssignRecommendationModalOpen;

      setIsAssignRecommendationModalOpen(isOpen);
    },
    [isAssignRecommendationModalOpen],
  );

  const recommendationTableCols: TDataColumns<TAccountRecommendationExtended> = [
    {
      title: 'ID',
      dataIndex: 'id',
      showSortDirections: true,
      hideColumn: true,
    },
    {
      title: 'Findings',
      dataIndex: 'findings',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: isBigScreen ? '16vw' : '20vw',
      onCell: () => {
        return getOneLineStyle(isBigScreen ? '16vw' : '20vw');
      },
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationSeverity',
      showSortDirections: true,
      width: isBigScreen ? '6vw' : '7vw',
      render: (_, record) => {
        return (
          <SeverityBadge type={(record.severity as unknown as string).toLowerCase() as any}>
            {record.severity}
          </SeverityBadge>
        );
      },
    },
    {
      title: 'Impact',
      dataIndex: 'easeOfFix',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationEaseOfFix',
      showSortDirections: true,
      width: isBigScreen ? '5vw' : '6vw',
    },
    {
      title: 'Effort',
      dataIndex: 'estimatedWork',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationEstimatedWork',
      showSortDirections: true,
      width: isBigScreen ? '5vw' : '6vw',
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      align: 'center',
      allowFiltering: true,
      type: 'textOptions',
      options: productOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: isBigScreen ? '6vw' : '8vw',
      onCell: () => {
        return getOneLineStyle(isBigScreen ? '6vw' : '8vw');
      },
    },
    {
      title: 'License',
      dataIndex: 'licenseNames',
      align: 'center',
      allowFiltering: true,
      type: 'textOptions',
      options: licenseOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: '10vw',
      hideColumn: true,
    },
    {
      title: 'Compliance',
      dataIndex: 'isCompliant',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'BooleanEnum',
      mapEnumLabels: [
        { value: 'true', label: 'Compliant' },
        { value: 'false', label: 'Not-Compliant' },
        { value: 'null', label: 'N/A' },
      ],
      showSortDirections: true,
      width: isBigScreen ? '5vw' : '9vw',
      align: 'center',
      render(_, record) {
        return (
          <ComplianceStatusBadge isCompliant={record.isCompliant as boolean}>
            {record.isCompliant === null ? 'N/A' : record.isCompliant ? 'Compliant' : 'Non-Compliant'}
          </ComplianceStatusBadge>
        );
      },
    },
    {
      title: 'Exemption',
      dataIndex: 'isExempted',
      allowFiltering: true,
      type: 'boolean',
      showSortDirections: true,
      width: isBigScreen ? '5vw' : '8vw',
      align: 'center',
      render(_, record) {
        return record.isExempted === 'True' ? (
          <ExemptionStatusBadge isExempted={record.isExempted === 'True'}>Exempted</ExemptionStatusBadge>
        ) : (
          'None'
        );
      },
    },
    {
      title: () => {
        return (
          <Popover content={<div style={{ maxWidth: '5vw', textAlign: 'center' }}>Validation Method</div>}>
            <div>
              <CloudSyncIcon />
            </div>
          </Popover>
        );
      },
      altLabel: 'Validation Method',
      dataIndex: 'validationMethod',
      allowFiltering: true,
      showSorterTooltip: false,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'RecommendationValidationMethod',
      showSortDirections: false,
      width: '5vw',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
      render: (_, record) => {
        const strippedText = stripHtml(record.description);
        return <span style={{ textAlign: 'center', marginTop: '5%' }}>{strippedText}</span>;
      },
      onCell: () => {
        return getOneLineStyle('25vw');
      },
    },
    {
      title: 'Operating System',
      dataIndex: 'operatingSystem',
      allowFiltering: true,
      showSorterTooltip: false,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'OperatingSystem',
      showSortDirections: false,
      hideColumn: true,
      width: '10vw',
    },
    {
      title: 'Scopes',
      dataIndex: 'scopes',
      allowFiltering: true,
      type: 'enumArray',
      enumValuesKey: 'RecommendationScope',
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
      render: (_, rec) => {
        return (
          <>
            {parseDbEnumArray(rec.scopes)
              .map((scope) => getEnumLabel('RecommendationScope', scope))
              .join(', ')}
          </>
        );
      },
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
    },
    {
      title: 'Tenant Wide',
      dataIndex: 'tenantWide',
      allowFiltering: true,
      type: 'boolean',
      showSortDirections: true,
      width: '25vw',
      hideColumn: true,
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigneeEmail',
      allowFiltering: true,
      type: 'text',
      align: 'center',
      showSortDirections: true,
      width: '20vw',
      hideColumn: true,
      render: (_, rec) => {
        return <>{rec.assigneeEmail || 'Unassigned'}</>;
      },
    },
    {
      title: 'Exemption Reason',
      dataIndex: 'exemptionReason',
      align: 'center',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '12vw',
      hideColumn: true,
    },
    {
      title: 'Exempted By',
      dataIndex: 'exemptedByEmail',
      align: 'center',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '5vw',
      hideColumn: true,
      render: (_, record) => {
        return (
          <div
            title={record.exemptedByEmail}
            style={{
              width: '5vw',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {record.exemptedByEmail}
          </div>
        );
      },
    },
    {
      title: 'Exemption Date',
      dataIndex: 'exemptionDate',
      allowFiltering: true,
      type: 'date',
      showSortDirections: true,
      width: '5vw',
      hideColumn: true,
    },
    {
      title: 'Assignment Date',
      dataIndex: 'assignmentTime',
      allowFiltering: true,
      type: 'date',
      showSortDirections: true,
      width: '5vw',
      hideColumn: true,
    },
    {
      title: 'Assigned By',
      dataIndex: 'assignedByEmail',
      align: 'center',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '5vw',
      hideColumn: true,
      render: (_, record) => {
        return (
          <div
            title={record.assignedByEmail || ''}
            style={{
              width: '5vw',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {record.assignedByEmail || 'N/A'}
          </div>
        );
      },
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   align: 'center',
    //   allowFiltering: true,
    //   type: 'enum',
    //   enumValuesKey: 'RecommendationCategory',
    //   showSortDirections: true,
    //   width: 100,
    // },
  ];

  const actionItems: TActionItems<TAccountRecommendationExtended>[] = [
    {
      label: 'Additional Details',
      key: 'additional-details',
      multiSelect: false,
      onClick: (item) => {
        handleOpenAdditionalDetailsModal(item);
      },
    },
    {
      label: 'Exempt',
      key: 'suppress',
      multiSelect: true,
      onClick: (items) => {
        handleSetExemptRecommendation(items);
      },
      show: (items) =>
        hasExemptPermission &&
        items.filter((item) => item.isExempted === false || item.isExempted === 'False').length === selectedRows.length,
    },
    {
      label: 'Unexempt',
      key: 'unsuppress',
      multiSelect: true,
      onClick: (items) => {
        handleSetUnexemptRecommendation(items);
      },
      show: (items) =>
        hasExemptPermission &&
        items.filter((item) => item.isExempted === true || item.isExempted === 'True').length === selectedRows.length,
    },
    {
      label: 'Mark Compliant',
      key: 'mark-compliant',
      multiSelect: true,
      onClick: (items) => {
        setComplianceAction('mark-compliant');
        handleMarkCompliance(items);
        onMarkCompliant?.(items);
      },
      show: (items) =>
        hasUpdateCompliancePermission &&
        items.filter((item) => {
          return (
            (item.isCompliant == null || item.isCompliant === false || item.isCompliant === 'False') &&
            getEnumLabel('RecommendationValidationMethod', 2) == (item.validationMethod as any)
          );
        }).length === selectedRows.length,
    },
    {
      label: 'Mark Non-Compliant',
      key: 'mark-non-compliant',
      multiSelect: true,
      onClick: (items) => {
        setComplianceAction('mark-non-compliant');
        handleMarkCompliance(items);
        onMarkNonCompliant?.(items);
      },
      show: (items) =>
        hasUpdateCompliancePermission &&
        items.filter(
          (item) =>
            (item.isCompliant == null || item.isCompliant === true || item.isCompliant === 'True') &&
            getEnumLabel('RecommendationValidationMethod', 2) == (item.validationMethod as any),
        ).length === selectedRows.length,
    },
    {
      label: 'Assignment',
      key: 'assign',
      multiSelect: true,
      onClick: (items) => {
        handleAssignRecommendation(items);
      },
      // only show option if the selected items has the same assignee or if all selected items dont have any user assigned
      show: (items) => hasAssignPermission && Array.from(new Set(items.map((item) => item.assignedToId))).length === 1,
    },
  ];

  return {
    recommendationTableCols,
    actionItems,
    selectedRows,
    selectedRecommendation,
    complianceAction,
    isAdditionalDetailsModalOpen,
    isExemptModalOpen,
    isMarkComplianceModalOpen,
    isAssignRecommendationModalOpen,
    setSelectedRows,
    handleOpenAdditionalDetailsModal,
    toggleAdditionalDetailsModal,
    toggleExemptModal,
    toggleComplianceModal,
    toggleAssignModalOpen,
  };
};
