import { useEffect, useRef, useState } from 'react';
import { useGetActiveTermsAndConditions } from '@app/api/termsAndConditions.api';
import { TTermsAndConditions } from '@app/types/termsAndConditions';
import { getEnumValue } from '@app/services/enum.service';

export function useTermsAndConditionsModal(
  userRole: number,
  termsApproved?: boolean,
  impersonationToken?: string | null,
) {
  const {
    mutateAsync: getActiveTermsAndConditions,
    data: fetchTerms,
    isSuccess: termsFetched,
  } = useGetActiveTermsAndConditions(userRole);

  const [terms, setTerms] = useState<TTermsAndConditions | null>(null);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const termsApprovedRef = useRef(termsApproved);

  useEffect(() => {
    if (impersonationToken || userRole == getEnumValue('UserRole', 'SuperAdmin') || userRole === undefined) {
      termsApprovedRef.current = true;
      return;
    }
    getActiveTermsAndConditions();
  }, [getActiveTermsAndConditions, userRole, impersonationToken]);

  useEffect(() => {
    if (termsFetched && fetchTerms) {
      setTerms(fetchTerms);
    }
  }, [fetchTerms, termsFetched]);

  useEffect(() => {
    if (termsApproved === undefined) {
      return;
    }
    setOpenTermsModal(!termsApproved);
  }, [termsApproved]);

  return { terms, openTermsModal, setOpenTermsModal };
}
