import React, { useMemo } from 'react';
import { Overlay } from '../../../../common/Overlay/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';
import { RiArrowLeftDoubleFill } from 'react-icons/ri';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { mobileOnly, tabletOnly, desktopOnly, isBigScreen } = useResponsive();

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);

  const showCollapse = tabletOnly || desktopOnly || isBigScreen;

  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={showCollapse ? 80 : 0}
        collapsible={isCollapsible}
        width={260}
        {...props}
      >
        <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
        {showCollapse && (
          <S.CollapseButton
            shape="circle"
            size="small"
            $isCollapsed={isCollapsed}
            icon={
              <RiArrowLeftDoubleFill
                fill="black"
                style={{
                  transform: `rotate(${isCollapsed ? 180 : 0}deg)`,
                }}
              />
            }
            onClick={toggleSider}
          />
        )}
        <S.SiderContent>
          <SiderMenu isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        </S.SiderContent>
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
