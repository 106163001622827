import { useGetModelItemsLazy } from '@app/api/table.api';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BASE_COLORS, RECOMMENDATION_DETAILS_MODAL_COLORS } from '@app/styles/themes/constants';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { TRecommendationPrerequisiteExtended } from '@app/types/recommendationPrerequisiteExtended';
import { Spin } from 'antd';
import { useMemo } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import styled from 'styled-components';

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #272727;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
`;

const ItemContainer = styled(HorizontalAlignedContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  :hover {
    cursor: pointer;
  }
`;

const StyledHTMLEditorContent = styled.div`
  font-weight: normal;
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  font-size: 16px;
`;

const CompletedText = styled.span`
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  font-size: 12px;
`;

const PercentageText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const prerequisiteCols: TDataColumns<TRecommendationPrerequisiteExtended> = [
  {
    title: 'Type',
    dataIndex: 'type',
    allowFiltering: true,
    type: 'text',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    allowFiltering: true,
    type: 'text',
  },
  {
    title: 'Percentage Compliant',
    dataIndex: 'percentageCompliant',
    allowFiltering: true,
    type: 'text',
  },
  {
    title: 'Compliant',
    dataIndex: 'compliant',
    allowFiltering: true,
    type: 'boolean',
  },
];

interface PrerequisitesSectionProps {
  activeTab: number;
  recommendation?: Partial<TAccountRecommendationExtended>;
  recommendationStories: any[];
}

export default function PreRequisitesSection({
  recommendation,
  activeTab,
  recommendationStories,
}: PrerequisitesSectionProps) {
  const licenses = useAppSelector((state) => state.app.licenses);

  const { data: prerequisuites, isFetching } = useGetModelItemsLazy<TRecommendationPrerequisiteExtended>({
    model: 'recommendationprerequisiteextended',
    columns: prerequisiteCols,
    queryParams:
      activeTab === 1 && recommendation?.recommendationId
        ? `$filter=recommendationId eq ${recommendation.recommendationId}`
        : undefined,
  });

  const recommendations = useMemo(() => {
    return prerequisuites?.items.filter((item) => item.type === 'recommendation') || [];
  }, [prerequisuites]);

  const licenseNames = useMemo(() => {
    if (!recommendation) {
      return 'No license associated with this recommendation.';
    }

    if (!!recommendation && (!recommendation.licenses || recommendation.licenses.length === 0)) {
      return 'No license associated with this recommendation.';
    }

    return recommendation.licenses
      ?.map((licenseId) => {
        return licenses?.find((l) => l.id === licenseId)?.displayName || '';
      })
      .join(', ');
  }, [recommendation]);

  return (
    <ColumnsContainer>
      {recommendation?.prerequisiteDescription && (
        <Col
          style={{
            backgroundColor: `${RECOMMENDATION_DETAILS_MODAL_COLORS.lightBlueBackground}`,
            borderRadius: '8px',
            padding: '8px 10px',
          }}
        >
          <Label>User impact details: </Label>
          <StyledHTMLEditorContent>
            <HtmlEditor initialValue={recommendation?.prerequisiteDescription} readOnly />
          </StyledHTMLEditorContent>
        </Col>
      )}
      {recommendation?.prerequisiteReference && (
        <Col>
          <Label>Reference: </Label>
          <Value>
            <a
              href={recommendation?.prerequisiteReference}
              target="_blank"
              rel="noreferrer"
              style={{ color: `${RECOMMENDATION_DETAILS_MODAL_COLORS.blueLink}`, fontSize: '16px' }}
            >
              {recommendation?.prerequisiteReference}
            </a>{' '}
          </Value>
        </Col>
      )}
      <Col>
        <Label>License/s: </Label>
        <Value>{licenseNames}</Value>
      </Col>
      <Col>
        <Label>Included in: </Label>
        <Value>{recommendation?.licensePlans || 'No license plans associated with this recommendation.'}</Value>
      </Col>
      {!isFetching && (
        <>
          {recommendations.length > 0 && (
            <Col>
              <Label>Related Recommendations:</Label>
              {recommendations.map((item) => (
                <ItemContainer
                  key={item.id}
                  onClick={() => {
                    const url = `/recommendations/${item.prerequisiteRecommendationId}`;
                    window.open(`${url}`, '_blank');
                  }}
                >
                  <span
                    style={{
                      color: `${BASE_COLORS.violetButton}`,
                      fontSize: '14px',
                      maxWidth: '80%',
                      gap: '12px',
                    }}
                  >
                    {item.name}
                    <FiExternalLink style={{ marginLeft: '8px' }} />
                  </span>
                  <span>{(item.compliant as any) === 'True' ? 'Compliant' : 'Non-Compliant'}</span>
                </ItemContainer>
              ))}
            </Col>
          )}
          {recommendationStories.length > 0 && (
            <Col>
              <Label>Related Stories:</Label>
              {recommendationStories.map((item) => (
                <ItemContainer
                  key={item.id}
                  onClick={() => {
                    const url = `/story/${item.storyId}`;
                    window.open(`${url}`, '_blank');
                  }}
                >
                  <span
                    style={{
                      color: `${BASE_COLORS.violetButton}`,
                      fontSize: '14px',
                      maxWidth: '80%',
                      gap: '12px',
                    }}
                  >
                    {item.story.name}
                    <FiExternalLink style={{ marginLeft: '8px' }} />
                  </span>
                  {/* TODO - restore display once percentageCompliant number is available
                    <span>
                    <PercentageText>{item.percentageCompliant}%</PercentageText>{' '}
                    <CompletedText>Completed</CompletedText>
                    </span> */}
                </ItemContainer>
              ))}
            </Col>
          )}
        </>
      )}
    </ColumnsContainer>
  );
}
