/* eslint-disable react/no-unescaped-entities */
import * as S from './LoginForm.styles';
import Logo from '../../../assets/logo-dark.png';
import MicrosoftIcon from '../../../assets/images/v2_microsoft.png';
import { httpApi } from '@app/api/http.api';
import { useEffect } from 'react';
import { displayMissingPermissionsNotification } from '@app/utils/utils';
import { FaRegCopyright } from 'react-icons/fa6';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const LoginForm = () => {
  const params = new URLSearchParams(window.location.search);
  const appDisplayName = useAppSelector((state) => state.app.appDisplayName);
  const error = params.get('error');

  useEffect(() => {
    const missingPermissions = params.get('missingPermissions');
    if (missingPermissions) {
      displayMissingPermissionsNotification();
    }
  }, []);

  const handleSSOLogin = async () => {
    const response = await httpApi.post('/microsoft/login');
    if (response?.status == 200) {
      const url = response.data;
      window.location.href = url;
    }
  };

  return (
    <S.LoginContainer>
      <S.LoginBackground />
      <S.LoginForm>
        <S.Logo src={Logo} alt="Logo" />
        <S.LoginFormWrapper>
          <S.LoginTitle>Sign In to Your Account</S.LoginTitle>
          <S.LoginDescription>Use your Microsoft Entra ID credentials to access {appDisplayName}</S.LoginDescription>
          <S.ErrorMessage>{error}</S.ErrorMessage>
          <S.SocialLogin variant="outlined" color="dark" size="large" onClick={handleSSOLogin}>
            <S.MicrosoftIcon src={MicrosoftIcon} alt="Microsoft Icon" />
            Sign in with Microsoft
          </S.SocialLogin>
          {/* <S.SignupText>
            Don't have a user? <Link to="/auth/sign-up">Click here to signup.</Link>
          </S.SignupText> */}
        </S.LoginFormWrapper>
        <S.CopyrightWrapper>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <FaRegCopyright size={'1rem'} />
          </span>
          {appDisplayName}. All rights reserved. Privacy • Terms
        </S.CopyrightWrapper>
      </S.LoginForm>
    </S.LoginContainer>
  );
};
