import React from 'react';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import * as S from './ConnectorDropdownStyles';
import { ConnectorHeaderContainer, HeaderActionIcon } from '../../Header.styles';
import { HeaderStats } from './HeaderStats';
import ConnectorsLink from '@app/components/icons/connectors/ConnectorsLink';
import { useGetAccountStatusInfo } from '@app/api/account.api';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';

export const ConnectorDropdown: React.FC = () => {
  const [isOpened, setOpened] = React.useState(false);
  const userRole = useAppSelector((state) => state.user.user?.role);

  const { data: accountStatusInfo, isSuccess } = useGetAccountStatusInfo(
    !!userRole && userRole !== getEnumValue('UserRole', 'SuperAdmin'),
  );
  const status = accountStatusInfo?.connectionStatus && accountStatusInfo?.alertStatus == 'OK' ? 'OK' : 'INVALID';

  const connectionStatus = isSuccess ? status : null;
  const { connectionColor, backgroundColor } = React.useMemo(() => {
    if (connectionStatus === 'INVALID') {
      return { connectionColor: 'var(--red-500)', backgroundColor: 'var(--red-100)' };
    }
    return { connectionColor: 'var(--green-500)', backgroundColor: 'var(--green-100)' };
  }, [connectionStatus]);

  return (
    <BasePopover
      trigger="click"
      content={<HeaderStats accountStatusInfo={accountStatusInfo} />}
      open={isOpened}
      onOpenChange={setOpened}
    >
      <ConnectorHeaderContainer color={backgroundColor}>
        <HeaderActionIcon type="text" icon={<ConnectorsLink />} />
        <S.ConnectorStatus color={connectionColor} />
      </ConnectorHeaderContainer>
    </BasePopover>
  );
};
