import { Checkbox } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { TTableFilterContext, useTableFilterContext } from '../../store/context';
import { TColumnHasEnumFilter, TDataCol } from '../../types';
import { getMappedEnumLabels } from '@app/utils/utils';
import { ALLOWED_DELETED_STATUS_KEYS } from '@app/constants/enumValues';

export function EnumFilter<T extends Record<string, unknown>>(props: TDataCol<T>) {
  const { enumValuesKey, mapEnumLabels } = props as TColumnHasEnumFilter;
  const mappedEnumValues = getMappedEnumLabels({ enumKey: enumValuesKey, mapEnumLabels });

  const { filterValuesMap } = useTableFilterContext<TTableFilterContext<T>>();

  const [selectedValues, setSelectedValues] = useState<number[]>([]);

  // this variable is used to track the single value on the filterMap that is associated with this filter
  const filterMapValue = useMemo(() => {
    return filterValuesMap?.get(props.dataIndex as string);
  }, [filterValuesMap]);

  useEffect(() => {
    if (!filterMapValue) {
      setSelectedValues([]);
      return;
    }

    const parsedSelectedValues = (JSON.parse(filterMapValue) as any[]).map((val) =>
      typeof val === 'number' || (props as any).enumValuesKey === 'BooleanEnum'
        ? typeof val === 'string'
          ? val.replaceAll("'", '')
          : val
        : parseInt(val),
    );
    setSelectedValues(parsedSelectedValues);
  }, [filterMapValue]);

  const handleOnSelect = (isChecked: boolean, value: number | string) => {
    let newSelectedValues: any[];

    if (isChecked) {
      newSelectedValues = [...selectedValues, value];
    } else {
      newSelectedValues = selectedValues.filter((sv) => sv != value);
    }
    setSelectedValues(newSelectedValues);

    const stringifiedSelectedValues = JSON.stringify(newSelectedValues);
    filterValuesMap?.set(props.dataIndex as string, stringifiedSelectedValues);
  };

  return (
    <div>
      {mappedEnumValues
        .filter(
          (val: any) =>
            ALLOWED_DELETED_STATUS_KEYS.includes((props as TColumnHasEnumFilter).enumValuesKey) ||
            val.label !== 'Deleted',
        )
        .map((val: any) => (
          <div
            key={val.value}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px', gap: '10px' }}
          >
            <Checkbox
              checked={selectedValues.includes(val.value)}
              onChange={(e) => handleOnSelect(e.target.checked, val.value)}
            />
            {val.label}
          </div>
        ))}
    </div>
  );
}
