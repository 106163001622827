import {
  useCreateTermsAndConditions,
  useDeleteTermsAndConditions,
  usePublishTermsAndConditions,
  useUpdateTermsAndConditions,
} from '@app/api/termsAndConditions.api';
import TermsAndConditionsForm, {
  TTermsAndConditionsForm,
} from '@app/components/apps/manage-terms-and-conditions/TermsAndConditionsForm';
import ConfirmationModal from '@app/components/apps/manage-groups/ConfirmationModal';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TTermsAndConditions } from '@app/types/termsAndConditions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTableV2 from '@app/components/tables/data-table/DataTable';
import { TableActions } from '@app/components/tables/data-table/components/TableActions';
import TableFiltersV2 from '@app/components/tables/data-table/components/TableFilters';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/data-table/types';
import { convertDateToReadablePastDate } from '@app/utils/utils';
import UseTermsAndConditionsForm from '@app/components/apps/manage-terms-and-conditions/useTermsAndConditionsForm';
import { getEnumValue } from '@app/services/enum.service';
import TermsAndConditionsStatusBadge from '@app/components/apps/manage-terms-and-conditions/components/TermsAndConditionsStatusBadge';

export default function ManageTermsAndConditionsPage() {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTerm, setselectedTerm] = useState<TTermsAndConditions | undefined>(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPublishConfirmationModalOpen, setIsPublishConfirmationModalOpen] = useState(false);
  const [pendingPublishData, setPendingPublishData] = useState<TTermsAndConditionsForm | null>(null);
  const { mutateAsync: createTermsAndConditions, isLoading: isCreating } = useCreateTermsAndConditions();
  const { mutateAsync: updateTermsAndConditions, isLoading: isUpdating } = useUpdateTermsAndConditions();
  const { mutateAsync: deleteTermsAndConditions } = useDeleteTermsAndConditions();
  const { mutateAsync: publishTermsAndConditions, isLoading: isPublishing } = usePublishTermsAndConditions();

  const termsAndConditionsColumns: TDataColumns<TTermsAndConditions> = [
    {
      title: 'Version',
      dataIndex: 'version',
      type: 'number',
      width: '8vw',
      align: 'center',
      render: (_, record) => record.version || '-',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      type: 'enum',
      enumValuesKey: 'TermsAndConditionsTypes',
      width: '8vw',
      allowFiltering: true,
      showSortDirections: true,
    },
    {
      title: 'Creation Time',
      dataIndex: 'creationTime',
      type: 'datetime',
      width: '8vw',
      render: (_, record) => {
        const readableDate = convertDateToReadablePastDate(record.creationTime as unknown as string);
        return readableDate;
      },
    },
    {
      title: 'Last Updated Time',
      dataIndex: 'lastUpdateTime',
      type: 'datetime',
      width: '8vw',
      render: (_, record) => {
        const updateDate = convertDateToReadablePastDate(record.lastUpdateTime as unknown as string);
        return updateDate;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      type: 'enum',
      enumValuesKey: 'TermsAndConditionsStatus',
      width: '8vw',
      allowFiltering: true,
      showSortDirections: true,
      render: (_, record) => {
        return (
          <TermsAndConditionsStatusBadge type={(record.status as unknown as string).toLowerCase() as any}>
            {record.status}
          </TermsAndConditionsStatusBadge>
        );
      },
    },
  ];

  const actionItems: TActionItems<TTermsAndConditions>[] = [
    {
      key: 'edit',
      label: 'Edit',
      multiSelect: false,
      onClick: (data) => {
        toggle();
        setIsEdit(true);
        setselectedTerm(data);
      },
      show: (items) => (items.status as any) === 'Draft',
    },
    {
      key: 'delete',
      label: 'Delete',
      multiSelect: false,
      onClick: (data) => {
        setselectedTerm(data);
        setIsConfirmationModalOpen(true);
      },
      show: (items) => (items.status as any) === 'Draft',
    },
    {
      key: 'publish',
      label: 'Publish',
      multiSelect: false,
      onClick: (data) => {
        setselectedTerm(data);
        setIsPublishConfirmationModalOpen(true);
      },
      show: (items) => (items.status as any) === 'Draft',
    },
  ];

  const tableFilterProps = useTableFiltersV2<TTermsAndConditions>({
    columns: termsAndConditionsColumns,
    model: 'termsandconditions',
    constantFilter: { status: { ne: getEnumValue('TermsAndConditionsStatus', 'Deleted') } },
    orderBy: {
      column: 'status',
      order: 'asc',
    },
    onApplyFilterCallback: () => {
      dataTableProps.resetPagination();
    },
  });

  const dataTableProps = useDataTable<TTermsAndConditions>({
    data: tableFilterProps.data?.items || [],
    columns: termsAndConditionsColumns,
    isLoading: tableFilterProps.isFetching,
    totalRows: tableFilterProps.data?.count,
    paginationEventCallback: ({ top, skip }) => {
      tableFilterProps.buildOdataQueryFromParams({ paginationProps: { top, skip } });
    },
    columnChangesEventCallback: (columns, displayedColumnIndexes) => {
      tableFilterProps.buildOdataQueryFromParams({ selectFields: displayedColumnIndexes });
    },
    onSortCallback: (sortObj) => {
      if (!sortObj || (!!sortObj && !sortObj.column)) return;
      tableFilterProps.buildOdataQueryFromParams({
        orderyByProps: { column: sortObj.column || '', order: sortObj.order || 'asc' },
      });
    },
    onRowClick: (data) => {
      toggle();
      setIsEdit(true);
      setselectedTerm(data);
    },
  });

  const handleSaveAndPublish = async (data: TTermsAndConditionsForm) => {
    const createdTerm = selectedTerm
      ? await publishTermsAndConditions({ id: selectedTerm.id })
      : await createTermsAndConditions(data);

    if (createdTerm?.id) {
      await publishTermsAndConditions({ id: createdTerm.id });
    }
    resetTable();
  };

  const handlePublishConfirmation = async () => {
    const termToPublish = pendingPublishData ? pendingPublishData : selectedTerm;
    if (termToPublish) {
      await handleSaveAndPublish(termToPublish);
      setPendingPublishData(null);
      setIsPublishConfirmationModalOpen(false);
      resetEditState();
      resetTable();
      toggle();
    }
  };

  const resetEditState = () => {
    setIsEdit(false);
    setselectedTerm(undefined);
  };

  const resetTable = () => {
    tableFilterProps.refetch();
    dataTableProps.clearSelection();
  };

  const { toggle, ...termsFormProps } = UseTermsAndConditionsForm({
    onOk: async (data, isSaveAndPublish) => {
      if (isSaveAndPublish) {
        setPendingPublishData(data);
        setIsPublishConfirmationModalOpen(true);
      } else if (isEdit && selectedTerm) {
        await updateTermsAndConditions({
          id: selectedTerm.id,
          data: data,
        });
        resetEditState();
        toggle();
      } else {
        await createTermsAndConditions(data);
        resetEditState();
        toggle();
      }
      resetTable();
    },
    onCancel: () => {
      resetEditState();
    },
    terms: selectedTerm,
  });

  const handleModalClose = () => {
    resetEditState();
    setIsConfirmationModalOpen(false);
  };

  const handleDeleteConfirmation = async () => {
    if (selectedTerm) {
      await deleteTermsAndConditions(selectedTerm.id);
      handleModalClose();
      resetTable();
    }
  };

  return (
    <>
      <PageTitle>{String(t('common.manageTermsAndConditions'))}</PageTitle>
      <ComponentHeader title={t('common.manageTermsAndConditions')} />
      <div style={{ marginBottom: '32px' }}>
        <TableFiltersV2 {...tableFilterProps}>
          <TableFiltersV2.Actions>
            <BaseCTAButton onClick={toggle}>Create Terms and Conditions</BaseCTAButton>
          </TableFiltersV2.Actions>
        </TableFiltersV2>
      </div>
      <DataTableV2 {...dataTableProps}>
        <DataTableV2.Actions>
          <TableActions actionItems={actionItems} />
        </DataTableV2.Actions>
      </DataTableV2>
      <TermsAndConditionsForm
        {...termsFormProps}
        isEdit={isEdit}
        isCreating={isCreating}
        isUpdating={isUpdating}
        isPublishing={isPublishing}
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleDeleteConfirmation}
        onCancel={handleModalClose}
        message="Are you sure you want to delete this Terms and Conditions?"
      />
      <ConfirmationModal
        open={isPublishConfirmationModalOpen}
        onOk={handlePublishConfirmation}
        onCancel={() => setIsPublishConfirmationModalOpen(false)}
        message="Are you sure you want to publish this Terms and Conditions? This will replace the existing active version."
      />
    </>
  );
}
