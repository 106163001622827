import { notificationController } from '@app/controllers/notificationController';
import { TTermsAndConditions } from '@app/types/termsAndConditions';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { httpApi } from './http.api';
import { TTermsAndConditionsForm } from '@app/components/apps/manage-terms-and-conditions/TermsAndConditionsForm';

export const createTermsAndConditions = async (data: TTermsAndConditionsForm) => {
  const response = await httpApi.post<any>('/terms-and-conditions/create', data);

  if (response?.status === 201) {
    notificationController.success({
      message: 'Terms and Conditions Successfully Created',
    });
  }

  return response?.data;
};

export const useCreateTermsAndConditions = () => {
  const queryClient = useQueryClient();

  return useMutation(createTermsAndConditions, {
    onSuccess: () => {
      queryClient.invalidateQueries('termsandconditions-odata');
    },
  });
};

export const updateTermsAndConditions = async ({ id, data }: { id: number; data: TTermsAndConditionsForm }) => {
  const response = await httpApi.patch<any>(`/terms-and-conditions/${id}`, data);

  if (response?.status === 200) {
    notificationController.success({
      message: 'Terms and Conditions Successfully Updated',
    });
  }

  return response?.data;
};

export const useUpdateTermsAndConditions = () => {
  const queryClient = useQueryClient();

  return useMutation(updateTermsAndConditions, {
    onSuccess: () => {
      queryClient.invalidateQueries('termsandconditions-odata');
    },
  });
};

export const publishTermsAndConditions = async ({ id }: { id: number }) => {
  const response = await httpApi.patch<any>(`/terms-and-conditions/${id}/publish`);
  if (response?.status === 200) {
    notificationController.success({
      message: 'Terms and Conditions Successfully Published',
    });
  }

  return response?.data;
};

export const usePublishTermsAndConditions = () => {
  const queryClient = useQueryClient();

  return useMutation(publishTermsAndConditions, {
    onSuccess: () => {
      queryClient.invalidateQueries('termsandconditions-odata');
    },
  });
};

export const getActiveTermsAndConditions = async (role: number) => {
  const response = await httpApi.get<any>(`/terms-and-conditions/active/${role}`);
  return response?.data;
};

export const useGetActiveTermsAndConditions = (role: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => getActiveTermsAndConditions(role), {
    onSuccess: () => {
      queryClient.invalidateQueries('termsandconditions-odata');
    },
  });
};

export const deleteTermsAndConditions = async (id: number) => {
  const response = await httpApi.delete<any>(`/terms-and-conditions/${id}`);

  if (response?.status === 200) {
    notificationController.success({
      message: 'Terms and Conditions Successfully Deleted',
    });
  }

  return response?.data;
};

export const useDeleteTermsAndConditions = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteTermsAndConditions, {
    onSuccess: () => {
      queryClient.invalidateQueries('termsandconditions-odata');
    },
  });
};

export const UpdateUserTerms = async ({ id }: { id: number }) => {
  const response = await httpApi.patch<any>(`/terms-and-conditions/user/${id}/version`);

  return response?.data;
};

export const useUpdateUserTerms = () => {
  const queryClient = useQueryClient();

  return useMutation(UpdateUserTerms, {
    onSuccess: () => {
      queryClient.invalidateQueries('termsandconditions-odata');
    },
  });
};
