import { useGetModelItems } from '@app/api/table.api';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { getEnumValue } from '@app/services/enum.service';
import { TRecommendation } from '@app/types/recommendation';
import { TRegulationControl } from '@app/types/regulationControl';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import Select from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-x: hidden;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
  flex-shrink: 0;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const FieldArea = styled.textarea<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
  resize: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TRegulationControlsForm = {
  controlId: string;
  text: string;
  recommendationIds: number[];
  regulationId?: number;
};

interface IRegulationControlsFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  regulationControl?: TRegulationControl;
}

export default function RegulationControlsForm({
  isEdit,
  isLoading,
  regulationControl,
  ...modalProps
}: IRegulationControlsFormProps) {
  const { data: recommendations, isFetching: isFetchingRecommendations } = useGetModelItems<TRecommendation>({
    model: 'Recommendation',
    queryParams: `$filter=(status eq ${getEnumValue('RecommendationStatus', 'Enabled')})`,
  });

  const recommendationOptions = useMemo(() => {
    if (!isFetchingRecommendations && !!recommendations) {
      const regControlNonValidRecommendations = regulationControl?.recommendations?.filter((r) => !r.isValid) || [];
      const recs = recommendations.items.concat(...regControlNonValidRecommendations);
      return [
        ...recs.map((item) => ({
          value: item.id,
          label: item.findings,
        })),
      ];
    }

    return [];
  }, [isFetchingRecommendations, recommendations]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<TRegulationControlsForm>({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (modalProps.open && !!regulationControl) {
      reset({
        controlId: regulationControl.controlId,
        text: regulationControl.text,
        recommendationIds: regulationControl.recommendations?.map((rec) => rec.id),
        regulationId: regulationControl.regulationId,
      });
    } else if (modalProps.open && !regulationControl) {
      reset({
        controlId: '',
        text: '',
        recommendationIds: undefined,
        regulationId: undefined,
      });
    }
  }, [modalProps.open, regulationControl]);

  const onSubmit: SubmitHandler<Partial<TRegulationControl>> = (data) => {
    modalProps.onOk?.(data);
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      style={{
        minWidth: '40vw',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{`${isEdit ? 'Edit' : 'Create'} Control`}</FormTitle>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Control ID:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.controlId} {...register('controlId', { required: true })} />
              {!!errors.controlId && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel style={{ alignSelf: 'start' }}>Text:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="text"
                rules={{
                  required: true,
                }}
                render={({ field }) => <FieldArea hasError={!!errors.text} {...field} />}
              />
              {!!errors.text && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Recommendations:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="recommendationIds"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    mode="multiple"
                    value={field.value}
                    showSearch
                    allowClear
                    status={errors.recommendationIds ? 'error' : ''}
                    style={{ width: '25.5vw' }}
                    options={recommendationOptions}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  >
                    {recommendationOptions.map((opt) => (
                      <Select.Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
              {!!errors.recommendationIds && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
        </FormContainer>
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton>{isEdit ? 'Update' : 'Create'}</CreateButton>
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}
