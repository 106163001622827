import React from 'react';
import styled, { css } from 'styled-components';

interface TermsAndConditionsStatusBadgeProps {
  children: React.ReactNode;
  type?: 'active' | 'draft' | 'past';
}

const TermsAndConditionsStatusBadge = ({ children, type = 'draft' }: TermsAndConditionsStatusBadgeProps) => {
  return <StyledStatusBadge $type={type}>{children}</StyledStatusBadge>;
};

export default TermsAndConditionsStatusBadge;

type Badge = {
  $type: 'active' | 'draft' | 'past';
};

const StyledStatusBadge = styled.div`
  border-radius: 5px;
  padding: 0.75rem 0.5rem;
  font-size: 0.75rem;
  height: 1.875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ $type }: Badge) => Variant($type)};
  fontweight: 500;
  width: 100%;
`;

const Variant = (variant = 'active') =>
  ({
    active: css`
      background-color: #30af5b1a;
      color: #30af5b;
      border: 1px solid #30af5b80;
    `,
    draft: css`
      background-color: #d5d5d5;
      color: #6e6e6e;
      border: 1px solid var(--grey-950);
    `,
    past: css`
      background-color: var(--orange-100);
      color: var(--orange-300);
      border: 1px solid #ff7b00;
    `,
  }[variant]);
