import { Button } from 'antd';
import { Container } from '@app/components/header/components/ConnectorDropdown/HeaderStats.styles';
import { useGetAccountStatusInfo } from '@app/api/account.api';
import { checkAppAccess } from '@app/api/account.api';
import { useEffect, useState } from 'react';
import { TAccountStatusInfo } from '@app/types/accountStatusInfo';
import Modal from 'antd/lib/modal/Modal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';
import { notificationController } from '@app/controllers/notificationController';

export default function AccountStatusModals() {
  const [accountStatusInfo, setAccountStatusInfo] = useState<TAccountStatusInfo>();
  const [appAccessStatus, setAppAccessStatus] = useState<string | null>(null);
  const [isCheckingAccess, setIsCheckingAccess] = useState(false);
  const userRole = useAppSelector((state) => state.user.user?.role);
  const appDisplayName = useAppSelector((state) => state.app.appDisplayName);

  const { data, isSuccess, isFetching, refetch } = useGetAccountStatusInfo(
    !!userRole && userRole !== getEnumValue('UserRole', 'SuperAdmin'),
  );

  useEffect(() => {
    if (isSuccess) {
      setAccountStatusInfo(data);
    }
  }, [data, isSuccess]);

  const handleCheckAppAccess = async () => {
    setIsCheckingAccess(true);
    const accessStatus: string = await checkAppAccess();
    setAppAccessStatus(accessStatus);
    setIsCheckingAccess(false);
    if (accessStatus === 'OK') {
      notificationController.success({
        message: 'Access Confirmed',
        description: 'Your connection to the app is active and verified.',
      });
      refetch();
    } else {
      // handle the case where connection status is not OK
    }
  };

  return (
    <Container>
      <Modal
        open={
          accountStatusInfo?.connectionStatus != null && accountStatusInfo?.connectionStatus === 'MISSING_ASSIGNMENTS'
        }
        footer={null}
        closable={false}
      >
        <h2>
          <strong>Action Required:</strong> Assign the <strong>&quot;Global Reader&quot;</strong> Role to the{' '}
          <strong>{appDisplayName}</strong> Enterprise Application
        </h2>
        <p>
          To complete the signup process, sign in to{' '}
          <a href="https://entra.microsoft.com" target="_blank" rel="noreferrer">
            <strong>Microsoft Entra admin center</strong>
          </a>{' '}
          as a <strong>Global Admin</strong> and follow these steps
        </p>
        <ol style={{ paddingLeft: 12 }}>
          <li>
            In the top search box, search for &quot;roles&quot; and select{' '}
            <strong>Microsoft Entra roles and administrators</strong>.
          </li>
          <li>
            From the list of roles, search for <strong>Global Reader</strong> and select it.
          </li>
          <li>
            Click <strong>Add assignments</strong>, then click <strong>Select members</strong>, and search for{' '}
            <strong>{appDisplayName}</strong>.
          </li>
          <li>
            Click <strong>Next</strong> and then <strong>Assign</strong>.
          </li>
        </ol>
        <p>Once completed, click the button below.</p>

        {appAccessStatus === 'MISSING_ASSIGNMENTS' && (
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Required action did not take place. Please complete the necessary assignments.
          </p>
        )}
        <Button type="primary" onClick={handleCheckAppAccess} disabled={isCheckingAccess}>
          {isCheckingAccess ? 'Checking...' : 'Check Access'}
        </Button>
      </Modal>

      <Modal
        open={
          accountStatusInfo?.status != null &&
          accountStatusInfo?.status === getEnumValue('AccountStatus', 'PendingInitialScan')
        }
        footer={null}
        closable={false}
      >
        Waiting for initial account configuration to complete. This may take a few minutes.
      </Modal>
    </Container>
  );
}
