import { Popover, Progress } from 'antd';

import { Container, StatsContainer, StatsTitle } from './HeaderStats.styles';
import ScoreIcon from '@app/components/icons/score/ScoreIcon';
import ConnectorsIcon from '@app/components/icons/connectors/ConnectorsIcon';
import { useGetAccountStatusInfo } from '@app/api/account.api';
import { useEffect, useState } from 'react';
import { TAccountStatusInfo } from '@app/types/accountStatusInfo';
import WarningIcon from '@app/components/icons/warning/WarningIcon';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import buildQuery from 'odata-query';

const alpha = '14';
const green = '#30af5b';
const red = '#ff0000';
const blue = '#3a50d1';

const TooltipContainer = styled.div`
  max-width: 30vw;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #272727;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const STANDARD_SECURITY_DRILLDOWN_FILTER = [
  {
    column: 'isCompliant',
    value: false,
    type: 'boolean',
    title: 'Compliant',
  },
];

const CUSTOM_SECURITY_DRILLDOWN_FILTER = [
  {
    column: 'isCompliant',
    value: false,
    type: 'boolean',
    title: 'Compliant',
  },
  {
    column: 'isExempted',
    value: false,
    type: 'boolean',
    title: 'Exempted',
  },
];

const ORDER_BY_SEVERITY_DESC = {
  column: 'severity',
  order: 'desc',
};

interface HeaderStatsProps {
  accountStatusInfo: TAccountStatusInfo | undefined;
}

export function HeaderStats({ accountStatusInfo }: HeaderStatsProps) {
  const buildStandardSecurityDrilldownFilter = buildQuery({
    select: [
      'findings',
      'severity',
      'easeOfFix',
      'estimatedWork',
      'productName',
      'isCompliant',
      'isExempted',
      'validationMethod',
    ],
    filter: STANDARD_SECURITY_DRILLDOWN_FILTER.map((filter) => ({
      [filter.column]: filter.value,
    })),
    orderBy: `${ORDER_BY_SEVERITY_DESC.column} ${ORDER_BY_SEVERITY_DESC.order}`,
  });

  const buildCustomSecurityDrilldownFilter = buildQuery({
    select: [
      'findings',
      'severity',
      'easeOfFix',
      'estimatedWork',
      'productName',
      'isCompliant',
      'isExempted',
      'validationMethod',
    ],
    filter: {
      and: CUSTOM_SECURITY_DRILLDOWN_FILTER.map((filter) => ({
        [filter.column]: filter.value,
      })),
    },
    orderBy: `${ORDER_BY_SEVERITY_DESC.column} ${ORDER_BY_SEVERITY_DESC.order}`,
  });

  const activeAlertsDrilldownFilter = buildQuery({
    select: ['lastActivationTime', 'lastUpdatedTime', 'name'],
  });

  const alertcolor = accountStatusInfo?.alertStatus == 'OK' ? green : red;
  const connectorcolor = accountStatusInfo?.connectionStatus == 'OK' ? green : red;

  return (
    <Container>
      <Popover
        content={
          <TooltipContainer>
            <span>{`This score reflects your security posture without any user-exemptions. It shows your current level of security based on all recommendations.`}</span>
          </TooltipContainer>
        }
      >
        <StyledLink
          to={`/recommendations${buildStandardSecurityDrilldownFilter}&scoreType=baseline`}
          replace
          style={{ display: 'block' }}
        >
          <StatsContainer color={green + alpha} cursor={'pointer'}>
            <Progress
              type="circle"
              format={(percent) => `${percent}%`}
              percent={accountStatusInfo?.standardSecurityScore}
              width={46}
              // used to keep the percent text black every time, otherwise it will change to green when 100%
              status="normal"
              strokeColor={green}
            />
            <StatsTitle>Baseline Score</StatsTitle>
          </StatsContainer>
        </StyledLink>
      </Popover>
      <Popover
        content={
          <TooltipContainer>
            {`This score reflects your security posture with user-exemptions taken into account. It shows your adjusted security level after excluding certain recommendations based on your choices (This score will always be equal or higher than Baseline Security Score).`}
          </TooltipContainer>
        }
      >
        <StyledLink
          to={`/recommendations${buildCustomSecurityDrilldownFilter}&scoreType=custom`}
          style={{ display: 'block' }}
        >
          <StatsContainer color={blue + alpha} cursor={'pointer'}>
            <Progress
              type="circle"
              format={(percent) => `${percent}%`}
              percent={accountStatusInfo?.customSecurityScore}
              width={46}
              status="normal"
              strokeColor={blue}
            />
            <StatsTitle>Custom Score</StatsTitle>
          </StatsContainer>
        </StyledLink>
      </Popover>

      <Popover
        content={
          <TooltipContainer>
            {accountStatusInfo && accountStatusInfo.numOfActiveAlerts > 0
              ? `You currently have ${accountStatusInfo?.numOfActiveAlerts} active alerts. `
              : 'No alerts are currently active.'}
            {accountStatusInfo && accountStatusInfo.numOfActiveAlerts > 0 && (
              <Link to={`/active-alerts${activeAlertsDrilldownFilter}`}>View Details</Link>
            )}
          </TooltipContainer>
        }
      >
        <StyledLink to={`/active-alerts${activeAlertsDrilldownFilter}`} style={{ display: 'block' }}>
          <StatsContainer color={alertcolor + alpha} cursor={'pointer'}>
            {accountStatusInfo?.alertStatus == 'OK' ? (
              <ScoreIcon fill={alertcolor} />
            ) : (
              <WarningIcon fill={alertcolor} />
            )}
            <StatsTitle>Alerts Status</StatsTitle>
          </StatsContainer>
        </StyledLink>
      </Popover>

      <Popover
        content={
          <TooltipContainer>
            {accountStatusInfo?.connectionStatus === 'OK'
              ? `System connecters are configured correctly`
              : `There is an issue with your system connectors. Please contact support`}
          </TooltipContainer>
        }
      >
        <StatsContainer color={connectorcolor + alpha}>
          <ConnectorsIcon fill={connectorcolor} />
          <StatsTitle>Connectors Status</StatsTitle>
        </StatsContainer>
      </Popover>
    </Container>
  );
}
