import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DEFAULT_LIMIT,
  DEFAULT_LIMIT_INTERVAL,
  DEFAULT_SKIP,
  DEFAULT_TOTAL_ROWS,
  SELECT_QUERY_PARAM_KEY,
  SKIP_QUERY_PARAM_KEY,
  TOP_QUERY_PARAM_KEY,
} from '../constants';
import { IDataTableProps } from '../DataTable';
import { usePagination } from './usePagination';
import { TDataColumns, TPaginationProps, TSortObject } from '../types';
import { SorterResult } from 'antd/lib/table/interface';

interface IUseDataTableProps<T extends Record<string, unknown>>
  extends Omit<IDataTableProps<T>, 'onSelectRows' | 'paginationProps'> {
  bookmarkFeature?: boolean; // determine if the displayed columns will be based on the url or the hideColumn property of each column
  totalRows?: number;
  defaultLimit?: number;
  onRowSelect?: (selectedRows: T[]) => void;
  paginationEventCallback?: (paginationProps: TPaginationProps) => void;
  columnChangesEventCallback?: (columns: TDataColumns<T>, displayedColumnIndexes: string[]) => void;
  onSortCallback?: (sortObj: TSortObject) => void;
}

export function useDataTable<T extends Record<string, unknown>>({
  bookmarkFeature = true,
  totalRows,
  defaultLimit,
  onRowSelect,
  paginationEventCallback,
  columnChangesEventCallback,
  onSortCallback,
  ...tableProps
}: IUseDataTableProps<T>) {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const [dataTableProps, setDataTableProps] = useState(tableProps);
  const [searchParams, setSearchParams] = useSearchParams();

  const { resetToDefaults, ...paginationProps } = usePagination({
    totalRows: totalRows || DEFAULT_TOTAL_ROWS,
    limit: !!searchParams.get(TOP_QUERY_PARAM_KEY)
      ? parseInt(searchParams.get(TOP_QUERY_PARAM_KEY)!)
      : defaultLimit
      ? defaultLimit
      : DEFAULT_LIMIT,
    skip: !!searchParams.get(SKIP_QUERY_PARAM_KEY) ? parseInt(searchParams.get(SKIP_QUERY_PARAM_KEY)!) : DEFAULT_SKIP,
    limitIntervals: DEFAULT_LIMIT_INTERVAL,
    selectedRows: selectedRows.length,
    onNext: ({ limit, skip }) => {
      paginationEventCallback?.({ skip, top: limit });
    },
    onPrev: ({ limit, skip }) => {
      paginationEventCallback?.({ skip, top: limit });
    },
    onPageClicked: ({ limit, skip }) => {
      paginationEventCallback?.({ skip, top: limit });
    },
    onLimitChanged: ({ limit, skip }) => {
      paginationEventCallback?.({ skip, top: limit });
    },
  });

  const displayedColumns = useMemo(() => {
    const columnsWithSortDirections = dataTableProps.columns.map((col) => {
      let newColumn = { ...col };

      if (col.showSortDirections) {
        newColumn = { ...col, sorter: (a: any, b: any) => 0 };
      } else {
        newColumn = { ...col, sorter: false };
      }

      return newColumn;
    });

    if (bookmarkFeature) {
      const selectIndexes = searchParams.get(SELECT_QUERY_PARAM_KEY)?.split(',') || [];

      return columnsWithSortDirections.filter(
        (col) => selectIndexes.find((index) => index === (col.dataIndex as string)) || !col.dataIndex, // show columns that are just an accumulation of another columns
      );
    }

    return columnsWithSortDirections.filter((col) => !col.hideColumn);
  }, [bookmarkFeature, dataTableProps.columns, searchParams]);

  useEffect(() => {
    setDataTableProps({ ...dataTableProps, data: tableProps.data });
    paginationProps.setTotalRows(totalRows || 0); // inferred that when the data is changed, the totalrows is also changed
  }, [tableProps.data]);

  function setDatTableProps(newProps: IDataTableProps<T>) {
    if (bookmarkFeature) {
      const selectedIndexes = newProps.columns.filter((col) => !col.hideColumn).map((col) => col.dataIndex as string);
      const selectedIndexesString = newProps.columns
        .filter((col) => !col.hideColumn)
        .map((col) => col.dataIndex as string)
        .join(',');
      searchParams.set(SELECT_QUERY_PARAM_KEY, selectedIndexesString);
      setSearchParams(searchParams);
      columnChangesEventCallback?.(newProps.columns, selectedIndexes);
    }

    setDataTableProps(newProps);
  }

  function clearSelection() {
    setSelectedRows([]);
  }

  function onSelectRows(selectedRows: T[]) {
    onRowSelect?.(selectedRows);
    setSelectedRows(selectedRows);
  }

  const onSort = (sortProps: SorterResult<T>) => {
    const newOrderBy: { column?: string; order?: 'asc' | 'desc' } = !sortProps.order
      ? {
          column: undefined,
          order: undefined,
        }
      : {
          column: sortProps.field?.toString() || '',
          order: sortProps.order === 'ascend' ? 'asc' : 'desc',
        };

    onSortCallback?.(newOrderBy);
  };

  return {
    ...dataTableProps,
    isLoading: tableProps.isLoading,
    displayedColumns,
    selectedRows,
    paginationProps,
    totalRows,
    setDatTableProps,
    clearSelection,
    onSelectRows,
    onSort,
    resetPagination: resetToDefaults,
  };
}
