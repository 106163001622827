import { useQuery } from 'react-query';

import { getRecommendation, getRecommendations } from '@app/api/recommendation.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { useMemo } from 'react';
import { updateAppValues } from '@app/api/table.api';
import { useRecommendationTableColumnsAndActions } from './useRecommendationTableCols';

export const useGetRecommendationExtended = ({
  recommendationId,
  recommendationProp,
}: {
  recommendationId: number;
  recommendationProp?: TAccountRecommendationExtended;
}) => {
  const { recommendationTableCols } = useRecommendationTableColumnsAndActions({});

  const {
    data: fetchedRecommendation,
    refetch: refetchRecommendationExtended,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['recommendationExtended', recommendationId],
    queryFn: () => getRecommendation(recommendationId),
    enabled: !!recommendationProp ? false : true,
    refetchOnWindowFocus: false,
  });

  const recommendation = useMemo(() => {
    if (!!recommendationProp) {
      return recommendationProp;
    }

    if (!fetchedRecommendation) return undefined;

    const mappedFetchedRecommendationValues = updateAppValues(fetchedRecommendation, recommendationTableCols);
    return mappedFetchedRecommendationValues;
  }, [fetchedRecommendation, recommendationProp]);

  return {
    recommendation,
    isLoading,
    error,
    refetchRecommendationExtended,
  };
};

export const useGetManyRecommendationExtended = ({
  recommendationIds,
  recommendationsProp,
}: {
  recommendationIds: number[];
  recommendationsProp?: TAccountRecommendationExtended[];
}) => {
  const { recommendationTableCols } = useRecommendationTableColumnsAndActions({});

  const {
    data: fetchedRecommendations,
    refetch: refetchRecommendationsExtended,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['recommendationsExtended'].concat(recommendationIds.map((id) => id.toString())),
    queryFn: () => getRecommendations(recommendationIds),
    enabled: !!recommendationsProp || recommendationIds.length === 0 ? false : true,
    refetchOnWindowFocus: false,
  });

  const recommendations = useMemo(() => {
    if (!!recommendationsProp) {
      // only return unique recommendations by id
      const uniqueRecommendations = Array.from(new Map(recommendationsProp.map((rec) => [rec.id, rec])).values());
      return uniqueRecommendations;
    }

    if (!fetchedRecommendations) return [];

    const mappedFetchedRecommendationsValues = fetchedRecommendations.map((recommendation) =>
      updateAppValues(recommendation, recommendationTableCols),
    );
    return mappedFetchedRecommendationsValues;
  }, [fetchedRecommendations, recommendationsProp]);

  return {
    recommendations,
    isLoading,
    error,
    refetchRecommendationsExtended,
  };
};
