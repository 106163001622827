import styled from 'styled-components';

export const ConnectorStatus = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 100%;
  position: absolute;
  bottom: 4px;
  right: 4px;
`;
