import { Modal, ModalFuncProps, Select, Spin } from 'antd';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TInsight } from '@app/types/insight';
import { useEffect } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';
import { getEnumStatusOptions } from '@app/utils/utils';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const FieldArea = styled.textarea<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
  resize: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TInsightsForm = {
  title: string;
  description: string;
  status: number;
  priority: number;
  reference: string;
};

interface IInsightsFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  insight?: TInsight;
}

const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
); // validate fragment locator

export default function InsightsForm({ isEdit, isLoading, insight, ...modalProps }: IInsightsFormProps) {
  const severityOptions = getEnumStatusOptions('InsightStatus');

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<TInsightsForm>({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (modalProps.open && !!insight) {
      reset({
        title: insight.title,
        description: insight.description,
        reference: insight.reference,
        priority: insight.priority,
        status: getEnumValue('InsightStatus', insight.status as any),
      });
    } else if (modalProps.open && !insight) {
      reset({
        title: '',
        description: '',
        reference: '',
        priority: 1,
        status: 1,
      });
    }
  }, [modalProps.open, insight]);

  const onSubmit: SubmitHandler<TInsightsForm> = (data) => {
    console.log(data);
    modalProps.onOk?.(data);
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      style={{
        minWidth: '600px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{`${isEdit ? 'Edit' : 'Create'} Insight`}</FormTitle>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Title:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.title} {...register('title', { required: true })} />
              {!!errors.title && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel style={{ alignSelf: 'start' }}>Description:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: true,
                }}
                render={({ field }) => <FieldArea hasError={!!errors.description} {...field} />}
              />
              {!!errors.description && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Status:</FieldLabel>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={severityOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {severityOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Link:</FieldLabel>
            <InputContainer>
              <FieldInput
                hasError={!!errors.reference}
                {...register('reference', {
                  pattern: {
                    value: urlPattern,
                    message: 'Invalid link',
                  },
                })}
              />
              {!!errors.reference && <ErrorMessage>{errors.reference.message}</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Priority:</FieldLabel>
            <InputContainer>
              <FieldInput type="number" hasError={!!errors.priority} {...register('priority', { required: true })} />
              {!!errors.priority && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
        </FormContainer>
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton>{isEdit ? 'Update' : 'Create'}</CreateButton>
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}
