import { Select, Spin } from 'antd';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';

import { useUpdateVendor } from '@app/api/user.api';
import { BaseCancelButton, BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { ModalFooterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TVendorsExtended } from '@app/types/vendors';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { getEnumValue } from '@app/services/enum.service';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useEffect } from 'react';
import { getSubscriptionTypeOptions } from '@app/utils/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--grey-950);
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <ModalFooterContainer>
      <BaseCancelButton type="button" onClick={() => onCancel?.()}>
        Cancel
      </BaseCancelButton>
      <BaseCTAButton
        htmlType="button"
        onClick={() => onApply?.()}
        disabled={onApplyDisabled}
        style={{ padding: '12px 32px' }}
      >
        Save
      </BaseCTAButton>
    </ModalFooterContainer>
  );
};

interface IEditVendorModalProps extends ModalFuncProps {
  vendor: TVendorsExtended;
  isLoading?: boolean;
  onUpdateSuccess?: () => void;
}

export default function EditVendorModal({ vendor, onUpdateSuccess, ...modalProps }: IEditVendorModalProps) {
  const { mutateAsync: updateVendor, isLoading: isUpdateVendorLoading } = useUpdateVendor();
  const userRole = useAppSelector((state) => state.user.user?.role);
  const subscriptionTypes = useAppSelector((state) => state.app.subscriptionTypes);
  const subscriptionTypesOptions = getSubscriptionTypeOptions();

  const {
    register,
    setValue,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<{ subscriptionTypeID: number; name: string }>({
    mode: 'onBlur',
    defaultValues: {
      subscriptionTypeID: vendor.subscriptionTypeId,
      name: vendor.vendorName,
    },
  });

  const watchedSubscriptionTypeID = useWatch({
    control,
    name: 'subscriptionTypeID',
  });

  useEffect(() => {
    reset({
      subscriptionTypeID: vendor.subscriptionTypeId,
      name: vendor.vendorName,
    });
  }, [vendor, reset]);

  // reset subscription type to default trial subscription if it is not valid anymore (subscription type has been disabled)
  useEffect(() => {
    const isValid = subscriptionTypes?.some((st) => st.id === watchedSubscriptionTypeID && st.status === 1);
    if (!isValid && watchedSubscriptionTypeID !== null) {
      setValue('subscriptionTypeID', 1);
    }
  }, [subscriptionTypes, watchedSubscriptionTypeID, setValue]);
  const hanldeEditVendor = async () => {
    const response = await updateVendor({
      vendorId: vendor.vendorId,
      vendorName: getValues('name'),
      subscriptionTypeId: watchedSubscriptionTypeID,
    });
    if (response) {
      onUpdateSuccess?.();
    }
  };

  const handleCloseModal = () => {
    reset({
      subscriptionTypeID: vendor.subscriptionTypeId,
      name: vendor.vendorName,
    });
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={'Edit Reseller'}
      destroyOnClose={true}
      closable={false}
      onCancel={handleCloseModal}
      footer={
        isUpdateVendorLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : (
          <ModalFooter onCancel={handleCloseModal} onApply={hanldeEditVendor} onApplyDisabled={!getValues('name')} />
        )
      }
    >
      <Container>
        <FieldContainer>
          <FieldLabel>Reseller Name:</FieldLabel>
          <InputContainer>
            <FieldInput hasError={!!errors.name} {...register('name', { required: true })} />
            {!!errors.name && <ErrorMessage>This field is required</ErrorMessage>}
          </InputContainer>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Subscription:</FieldLabel>
          <InputContainer>
            <Controller
              control={control}
              name="subscriptionTypeID"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  disabled={userRole === getEnumValue('UserRole', 'Vendor')}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={subscriptionTypesOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  onChange={(value) => {
                    field.onChange(value === null ? null : value);
                  }}
                />
              )}
            />
          </InputContainer>
        </FieldContainer>
      </Container>
    </Modal>
  );
}
