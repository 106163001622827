import { useGetRecommendationRegulations } from '@app/api/regulation.api';
import { parseDbEnumArray } from '@app/components/common/common';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { regulationInformationOnRenderFilter } from '@app/components/layouts/main/sider/sidebarNavigation';
import { getEnumLabel } from '@app/services/enum.service';
import { RECOMMENDATION_DETAILS_MODAL_COLORS } from '@app/styles/themes/constants';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HalfContainer = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;
  max-width: 50%;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #272727;
`;

const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
`;

const Row = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledHTMLEditorContent = styled.div`
  font-weight: normal;
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  font-size: 16px;
`;

const ComplianceStatus = styled.div<{ isCompliant: boolean | null }>`
  font-weight: 400;
  font-size: 14px;
  color: #272727;
  background-color: ${({ isCompliant }) => (isCompliant === null ? '#F6F8FE' : isCompliant ? '#E6F4EA' : '#FFF1F1')};
  color: ${({ isCompliant }) => (isCompliant === null ? '#333' : isCompliant ? '#1e803e' : '#D81D2D')};
  width: fit-content;
  padding: 2px 8px;
  border-radius: 6px;
`;

const RiskLevelContainer = styled.div<{ severity: 'High' | 'Moderate' | 'Low' }>`
  font-weight: 400;
  font-size: 14px;
  color: ${({ severity }) =>
    severity === 'High'
      ? '#D81D2D'
      : severity === 'Moderate'
      ? '#ff7455'
      : severity === 'Low'
      ? '#1e803e'
      : `${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText}`};
  background-color: ${({ severity }) =>
    severity === 'High'
      ? '#FFF1F1'
      : severity === 'Moderate'
      ? '#ff74551a'
      : severity === 'Low'
      ? '#E6F4EA'
      : '#F6F8FE'};
  width: fit-content;
  padding: 2px 8px;
  border-radius: 6px;
`;

const UserImpact = styled.div<{ severity: 'Substantial' | 'Moderate' | 'Minimal' }>`
  font-weight: 400;
  font-size: 14px;
  color: ${({ severity }) =>
    severity === 'Substantial'
      ? '#D81D2D'
      : severity === 'Moderate'
      ? '#ff7455'
      : severity === 'Minimal'
      ? '#1e803e'
      : `${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText}`};
  background-color: ${({ severity }) =>
    severity === 'Substantial'
      ? '#FFF1F1'
      : severity === 'Moderate'
      ? '#ff74551a'
      : severity === 'Minimal'
      ? '#E6F4EA'
      : '#F6F8FE'};
  width: fit-content;
  padding: 2px 8px;
  border-radius: 6px;
`;

const OperatingSystemContainer = styled.div`
  font-size: 14px;
  color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.grayText};
  background-color: ${RECOMMENDATION_DETAILS_MODAL_COLORS.lightBlueBackground};
  width: fit-content;
  padding: 2px 8px;
  border-radius: 6px;
`;

interface IOverviewSectionProps {
  recommendation: TAccountRecommendationExtended;
}

export default function OverviewSection({ recommendation }: IOverviewSectionProps) {
  const { data: recommendationRegulations } = useGetRecommendationRegulations(recommendation.recommendationId);

  return (
    <ColumnsContainer>
      <Col style={{ fontSize: '18px' }}>{recommendation?.findings}</Col>
      <Col
        style={{
          backgroundColor: `${RECOMMENDATION_DETAILS_MODAL_COLORS.lightBlueBackground}`,
          borderRadius: '8px',
          padding: '8px 10px',
        }}
      >
        <Label>Risk description: </Label>
        <StyledHTMLEditorContent>
          {recommendation?.description && <HtmlEditor initialValue={recommendation.description} readOnly />}
        </StyledHTMLEditorContent>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Corrective action: </Label>
            <Value>{recommendation?.correctiveAction}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Compliance: </Label>
            <ComplianceStatus isCompliant={recommendation?.isCompliant as boolean}>
              {recommendation?.isCompliant == null
                ? 'N/A'
                : recommendation?.isCompliant
                ? 'Compliant'
                : 'Non-Compliant'}
            </ComplianceStatus>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Exempted: </Label>
            <Value>{!!!recommendation?.isExempted || recommendation?.isExempted === 'False' ? 'No' : 'Yes'}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Product: </Label>
            <Value>{recommendation?.productName}</Value>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Assigned to: </Label>
            <Value>{recommendation?.assigneeEmail || 'Unassigned'}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Risk level: </Label>
            <RiskLevelContainer severity={recommendation?.severity as unknown as 'High' | 'Moderate' | 'Low'}>
              {recommendation?.severity}
            </RiskLevelContainer>
          </HalfContainer>
        </Row>
      </Col>

      <Col>
        <Row>
          <HalfContainer>
            <Label>Estimated work: </Label>
            <UserImpact severity={recommendation?.easeOfFix as string as 'Substantial' | 'Moderate' | 'Minimal'}>
              {recommendation?.easeOfFix}
            </UserImpact>
          </HalfContainer>
          <HalfContainer>
            <Label>User impact: </Label>
            <UserImpact severity={recommendation?.easeOfFix as string as 'Substantial' | 'Moderate' | 'Minimal'}>
              {recommendation?.easeOfFix}
            </UserImpact>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Operating system: </Label>
            <OperatingSystemContainer>{recommendation?.operatingSystem}</OperatingSystemContainer>
          </HalfContainer>
          <HalfContainer>
            <Label>Regulations: </Label>
            {recommendationRegulations?.map((regulation) => (
              <Value key={`recommendation-regulation-${regulation.id}`}>
                <Link
                  to={`/regulation-information/${regulation.id}${regulationInformationOnRenderFilter}`}
                  target="__blank"
                >
                  {regulation.name}
                </Link>
              </Value>
            ))}
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Scopes: </Label>
            <Value>
              {parseDbEnumArray(recommendation?.scopes || '')
                ?.map((scope) => getEnumLabel('RecommendationScope', scope))
                .join(', ')}
            </Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Reference: </Label>
            <Value>
              <a
                href={recommendation?.reference}
                target="_blank"
                rel="noreferrer"
                style={{ color: `${RECOMMENDATION_DETAILS_MODAL_COLORS.blueLink}` }}
              >
                {recommendation?.reference && recommendation.reference.length > 50
                  ? `${recommendation.reference.substring(0, 50)}...`
                  : recommendation?.reference}
              </a>
            </Value>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Tenant wide: </Label>
            <Value>{recommendation?.tenantWide ? 'Yes' : 'No'}</Value>
          </HalfContainer>
        </Row>
      </Col>
    </ColumnsContainer>
  );
}
