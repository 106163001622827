import styled from 'styled-components';
import { BaseModalTitle, ModalFooterContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useUpdateUserTerms } from '@app/api/termsAndConditions.api';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { TTermsAndConditions } from '@app/types/termsAndConditions';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { BaseCTAButton } from '@app/components/common/BaseButton/BaseButton.styles';
import { useCallback } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EditorContainer = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  padding: 0 16px;
`;

const CenteredModalFooter = styled(ModalFooterContainer)`
  justify-content: center;
`;

const BoldTitle = styled(BaseModalTitle)`
  font-weight: 600;
  margin-bottom: 4px;
`;

const Subtitle = styled.div`
  font-size: 1rem;
  color: var(--grey-500);
`;

interface ITermsAndConditionsModalProps extends ModalFuncProps {
  terms: TTermsAndConditions;
  newVersion?: boolean;
}

export default function TermsAndConditionsModal({
  terms,
  newVersion = false,
  ...modalProps
}: ITermsAndConditionsModalProps) {
  const { mutateAsync: updateUserTerms } = useUpdateUserTerms();
  const userId: number = useSelector<RootState>((state) => state.user.user?.id) as number;
  const queryClient = useQueryClient();

  const handleAgree = useCallback(async () => {
    try {
      await updateUserTerms({ id: userId });
      await queryClient.invalidateQueries('whoAmI');
      modalProps.onCancel?.();
    } catch (error) {
      console.error('Failed to update user terms:', error);
    }
  }, [updateUserTerms, userId, queryClient, modalProps]);

  const Footer = () => (
    <CenteredModalFooter>
      <BaseCTAButton htmlType="button" onClick={handleAgree}>
        Agree To The Terms And Conditions
      </BaseCTAButton>
    </CenteredModalFooter>
  );

  return (
    <Modal
      style={{ minWidth: '50vw' }}
      {...modalProps}
      destroyOnClose
      closable={!newVersion}
      maskClosable={!newVersion}
      keyboard={!newVersion}
      footer={newVersion ? <Footer /> : null}
      title={
        newVersion ? (
          <>
            <BoldTitle>We&apos;ve updated our Terms and Conditions!</BoldTitle>
            <Subtitle>Please review the changes below and accept to continue using our service.</Subtitle>
          </>
        ) : (
          <BoldTitle>Terms And Conditions</BoldTitle>
        )
      }
      centered
      closeIcon={newVersion ? null : modalProps.closeIcon}
    >
      <Container>
        <EditorContainer>
          <HtmlEditor readOnly initialValue={terms.content} />
        </EditorContainer>
      </Container>
    </Modal>
  );
}
